import axios from 'axios';
import debug from 'debug';
import { ref, onUnmounted, Ref, computed, onMounted } from 'vue';
import * as Sentry from '@sentry/vue';
import { HistoricalPrice, PriceServiceMessage } from '@/types/energy-exchange';
import { useNotificationStore, useUserStore } from '@/store';
import { NotificationType } from '@/types/notification';

interface PriceServiceWebSocket {
  socket: Ref<WebSocket | null>;
  messages: Ref<PriceServiceMessage[]>;
  connect: (url: string) => void;
  disconnect: () => void;
  status: Ref<string>;
  latestPrices: () => void;
}

const messages = ref<PriceServiceMessage[]>([]);
export const latestIntradayPrice = ref<HistoricalPrice | null>(null);
export const latestBidAsk = computed(() => {
  const latestValidAsk = messages.value.find((msg) => msg.ask);
  const latestValidBid = messages.value.find((msg) => msg.bid);

  return {
    bid: latestValidBid,
    ask: latestValidAsk,
  };
});

const log = debug('websocket:price-service');

export function useWebSocketPriceService(): PriceServiceWebSocket {
  const socket = ref<WebSocket | null>(null);
  const status = ref('Disconnected');
  const manuallyDisconnected = ref(false);
  const userStore = useUserStore();
  const { addNotification } = useNotificationStore();

  const RECONNECT_INTERVAL = 3000;
  const MAX_RECONNECT_ATTEMPTS = 7;
  const PRICE_POLLING_INTERVAL = 3000;
  const WS_RETRY_TIMEOUT = 300000;

  const reconnectAttempts = ref(0);
  const reconnectTimeout = ref<number | null>(null);
  const backupPricePollingInterval = ref<ReturnType<typeof setInterval> | null>(null);
  const wsRetryTimeoutAfterFailureToReconnect = ref<number | null>(null);

  const latestPrices = async (polling: boolean = false) => {
    try {
      const res = await axios.get(
        `${import.meta.env.VITE_PRICE_SERVICE_URL}/price/latest?commodity=ETS`,
        {
          headers: {
            Authorization: `Bearer ${userStore.accessToken}`,
            'Content-Type': 'application/json',
          },
        },
      );
      if (polling) {
        res.data.forEach((data: PriceServiceMessage) => {
          messages.value.splice(0, 0, data);
        });
        // else if is a race condition, if websocket loads faster, no need to splice data to top of array.
      } else if (!latestBidAsk.value.bid && !latestBidAsk.value.ask) {
        res.data.forEach((data: PriceServiceMessage) => {
          if (data.bid && !latestBidAsk.value.bid) {
            messages.value.push(data);
          }
          if (data.ask && !latestBidAsk.value.ask) {
            messages.value.push(data);
          }
        });
      }
    } catch (error) {
      addNotification({
        message: String(error),
        type: NotificationType.ERROR,
        showIcon: true,
      });
    }
  };

  const stopBackupPricePolling = () => {
    if (backupPricePollingInterval.value) {
      clearInterval(backupPricePollingInterval.value);
      backupPricePollingInterval.value = null;
    }
  };

  const startPricePolling = () => {
    stopBackupPricePolling();
    latestPrices(true);
    backupPricePollingInterval.value = setInterval(
      () => latestPrices(true),
      PRICE_POLLING_INTERVAL,
    );
  };

  const connect = () => {
    const url = `${import.meta.env.VITE_PRICE_SERVICE_URL}/ws`;
    log('Attempting to connect to %s', url);
    socket.value = new WebSocket(url);

    socket.value.onopen = () => {
      // Stop backup price polling when reconnected
      stopBackupPricePolling();
      status.value = 'Connected';
      const subscriptionMessage = {
        action: 'subscribe',
        channel: 'ETS',
        token: userStore.accessToken,
      };
      socket.value?.send(JSON.stringify(subscriptionMessage));
      reconnectAttempts.value = 0;
      log('WebSocket connection opened');
    };

    socket.value.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.id && (message.ask || message.bid)) {
        messages.value.splice(0, 0, message);

        // Limit length of array for long time performance
        if (messages.value.length > 500) {
          messages.value.splice(300);
        }
      }

      log('Received message: %o', message);
    };

    socket.value.onclose = (event) => {
      status.value = 'Disconnected';

      if (!manuallyDisconnected.value) {
        log('WebSocket disconnected. Code: %d, Reason: %s', event.code, event.reason);
        Sentry.captureException(new Error('websocket connection failed'), {
          extra: {
            code: event.code,
            reason: event.reason ?? 'unknown',
          },
        });
        if (reconnectAttempts.value < MAX_RECONNECT_ATTEMPTS) {
          reconnectAttempts.value++;
          reconnectTimeout.value = window.setTimeout(() => {
            log('Attempting to reconnect (%d/%d)', reconnectAttempts.value, MAX_RECONNECT_ATTEMPTS);
            connect();
          }, RECONNECT_INTERVAL);
        } else {
          // Start backup price system while we wait for websocket to start working again
          startPricePolling();

          // Try to reconnect WebSocket after 5 minutes
          wsRetryTimeoutAfterFailureToReconnect.value = window.setTimeout(() => {
            reconnectAttempts.value = 0;
            connect();
          }, WS_RETRY_TIMEOUT);

          Sentry.captureException(new Error('websocket failed to reconnect'));
        }
      }
    };

    socket.value.onerror = () => {
      status.value = 'Error';
      // Sentry.captureException(new Error('websocket onerror'));
    };
  };

  const disconnect = () => {
    if (reconnectTimeout.value) {
      clearTimeout(reconnectTimeout.value);
    }
    if (wsRetryTimeoutAfterFailureToReconnect.value) {
      clearTimeout(wsRetryTimeoutAfterFailureToReconnect.value);
    }
    stopBackupPricePolling();
    socket.value?.close();
    socket.value = null;
    status.value = 'Disconnected';
    manuallyDisconnected.value = true;
    log('WebSocket disconnected');
  };

  let heartbeatInterval: null | number = null;

  onUnmounted(() => {
    disconnect();
    if (heartbeatInterval) {
      clearInterval(heartbeatInterval);
    }
  });

  onMounted(() => {
    heartbeatInterval = window.setInterval(() => {
      if (socket.value?.readyState === WebSocket.OPEN) {
        socket.value.send(JSON.stringify({ action: 'heartbeat' }));
        log('Heartbeat sent');
      }
    }, 30000);
  });

  return {
    socket,
    messages,
    connect,
    disconnect,
    status,
    latestPrices,
  };
}
